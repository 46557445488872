import React from 'react';
import './App.css';

function App() {
  return (
    <div id="page">
      {/* <div id="main">
        <ul id="top_menu">
          <li className="blog">
            <a href="http://fubu_la_dub.livejournal.com/" title="" target="_blank">
              блог
            </a>
          </li>
          <li className="online">
            <a href="http://www.realfm.ru:8000/listen.pls" title="Онлайн трансляция радио">
              трансляция
            </a>
          </li>
          <li className="archive">
            <a href="http://undergroundsound.ru/files/archive/fubu/" title="Скачать архив передач">
              архив передач
            </a>
            <ul>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-04-13_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 13/04/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-04-20_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 20/04/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-04-27_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 27/04/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-05-04_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 04/05/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-05-11_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 11/05/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-05-18_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 18/05/08
                </a>
              </li>
              <li>
                <a
                  href="http://undergroundsound.ru/files/archive/fubu/2008-05-26_Fubu_-_Underground_Sound.mp3"
                  title=""
                >
                  Underground Sound 26/05/08
                </a>
              </li>
            </ul>
          </li>
          <li className="article">
            <a href="http://undergroundsound.ru/content/article/" title="Статьи">
              статьи
            </a>
          </li>
          <li className="community">
            <a
              href="http://community.livejournal.com/undergroundsoun/"
              title="Интернет сообщество"
              target="_blank"
            >
              сообщество
            </a>
          </li>
        </ul>
        <div id="text">
          <h2>
            Fubu la dub на пиратском радио
            <br />
            UndergroundSound 98.3 FM
          </h2>
          <br />
          <div id="on_air">по Воскресеньям с 20 до 22</div>
          <p>
            <span>Город:</span>
            <strong>
              <a href="/troitsk.html" title="">
                Троицк
              </a>
            </strong>
          </p>
          <p>
            <span>ICQ:</span>
            <strong>253-174-698</strong>
          </p>
          <p>
            <span>SMS:</span>
            <strong>903-252-03-30</strong>
          </p>
          <p>
            <span>E-Mail:</span>
            <strong>
              <a href="mailto:fubu@undergroundsound.ru" title="">
                fubu@undergoundsound.ru
              </a>
            </strong>
          </p>
          <p>
            <span>Направление:</span>
            <strong>
              <a href="/hardcore.html" title="Hardcore">
                Hardcore
              </a>
              ,
              <a href="/happy_hardcore.html" title="Happy Hardcore">
                Happy Hardcore
              </a>
              ,<br />
              <a href="/breaks.html" title="Breaks">
                Breaks
              </a>
              ,{' '}
              <a href="/hardcore_breaks.html" title="Hardcore Breaks">
                Hardcore Breaks
              </a>
              ,
              <a href="/hard_techno.html" title="Hard Techno">
                Hard Techno
              </a>
              ,<br />
              <a href="/breakcore.html" title="Breakcore">
                Breakcore
              </a>
              ,{' '}
              <a href="/raggacore.html" title="Raggacore">
                Raggacore
              </a>
            </strong>
          </p>
        </div>
      </div>

      <div id="footer">
        <p>
          © artwork
          <a href="http://www.adamsimpson.com/" title="" target="_blank">
            Adam Simpson
          </a>
          edit by
          <a href="http://www.artemiylanc.ru/" title="" target="_blank">
            Artemiy Lanc
          </a>
        </p>
      </div>
      <div id="scroll_fix"></div> */}
    </div>
  );
}

export default App;
